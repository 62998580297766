<template>
  <div
    class="bg-white border mb-4"
    style="
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.14);
      border-radius: 4px;
      padding: 48px;
    "
  >
    <label class="col-4 pe-2 mb-3 fs-4 fw-bold">修改密碼</label><hr>
    <div class="row mb-4 align-items-center g-0">
      <label class="col-4 pe-2 my-2 fw-bold">目前密碼</label>
      <InputPassword
        id="originPassword"
        v-model:input="state.originPassword"
        type="text"
        class="mb-4"
        direction="row"
        :required="true"
        autocomplete="on"
        placeholder="請輸入原密碼"
        :err-message="v$.originPassword?.$errors[0]?.$message || ''"
        @focus="v$.originPassword.$touch"
      />
      <label class="col-4 pe-2 my-2 fw-bold">新密碼</label>
      <InputPassword
        id="resetPassword"
        v-model:input="state.resetPassword"
        type="text"
        class="mb-4"
        direction="row"
        :required="true"
        autocomplete="on"
        placeholder="請輸入新密碼"
        :err-message="v$.resetPassword?.$errors[0]?.$message || ''"
        @focus="v$.resetPassword.$touch"
        @enter="updateMemberPassword"
      />
      <label class="col-4 pe-2 my-2 fw-bold">確認新密碼</label>
      <InputPassword
        id="resetCheckPassword"
        v-model:input="state.resetCheckPassword"
        type="text"
        class="mb-4"
        direction="row"
        :required="true"
        autocomplete="on"
        placeholder="請再次輸入密碼"
        :err-message="v$.resetCheckPassword?.$errors[0]?.$message || ''"
        @focus="v$.resetCheckPassword.$touch"
        @enter="updateMemberPassword"
      />
    </div>
    <div class="d-flex justify-content-center my-3">
      <button
        class="btn btn-primary text-white flex-grow-1"
        type="button"
        style="border-radius: 100px; height: 52px"
        @click="updateMemberPassword"
      >
        儲存
      </button>
    </div>
  </div>
</template>
<script>
import InputPassword from '@/components/InputPassword.vue'
import useVuelidate from '@vuelidate/core'
import {
  requiredHelper,
  passwordHelper,
  helpers,
  sameAs
} from '@/hooks/useVuelidate.js'
import { useStore } from 'vuex'
import { computed, reactive, inject, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useErrorMessage } from '@/hooks/errorMessage.js'

export default {
  name: 'MemberPassword',
  components: {
    InputPassword
  },
  setup (props) {
    const { getters, dispatch, commit } = useStore()
    const { push } = useRouter()
    const { getErrorMessage } = useErrorMessage()

    const memberID = computed(() => getters['member/getMemberID'])

    const setAlert = inject('setAlert')

    const resetPassword = ref('')
    const state = reactive({
      originPassword: '',
      resetPassword: resetPassword,
      resetCheckPassword: ''
    })

    const rules = {
      originPassword: { requiredHelper },
      resetPassword: { requiredHelper, passwordHelper },
      resetCheckPassword: {
        requiredHelper,
        passwordHelper,
        sameAsRef: helpers.withMessage(
          '請確認兩次密碼是否一致',
          sameAs(resetPassword)
        )
      }
    }

    const v$ = useVuelidate(rules, state)

    const logoutMember = () => {
      commit('member/setMemberID', { data: null })
      commit('cart/setCart', { data: [] })
      commit('cart/setCartMount', { data: 0 })
      // localStorage.removeItem('memberID')
      push('/login')
    }

    const updateMemberPassword = async () => {
      v$.value.$touch()
      if (!v$.value.$error) {
        const payload = {
          memberID: memberID.value,
          originPassword: state.originPassword,
          resetPassword: state.resetPassword
        }

        try {
          const response = await dispatch('member/updateMemberPassword', {
            payload
          })

          // getErrorMessage
          if (response.data.errMsg) {
            setAlert(true, false, getErrorMessage(response))
          } else {
            setAlert(true, true, '更改密碼成功，請重新登入')
            logoutMember()
          }

          return response
        } catch (error) {
          // console.log('get user info error: ' + ', ' + error)
          setAlert(true, false, `更改密碼失敗: ${error}`)
        }
      }
    }

    return { v$, state, updateMemberPassword }
  }
}
</script>
<style lang="scss" module></style>
