<template>
  <div class="position-relative">
    <div
      class="d-flex flex-column"
    >
      <div
        class="position-relative d-flex align-items-center"
      >
        <input
          :id="id"
          v-model="inputValue"
          class="form-control"
          :class="[$style[`input`], {'border-danger': errMessage!==''}]"
          :type="[showPassword ? 'text' : 'password']"
          :maxlength="maxlength"
          :placeholder="placeholder"
          @input="updateInput($event)"
          @focus="$emit('focus')"
          @keydown.enter="$emit('enter')"
        >
        <div
          v-if="label !== ''"
          class="d-flex align-items-center order-first"
          :class="[[$style['label']], [direction==='row' ? 'col-4 text-dark justify-content-end pe-2' : `position-absolute top-0 small ${[$style[`label-column`]]}`]]"
        >
          <span
            :for="id"
            class="form-label"
          >{{ label }}
            <span
              v-if="required"
              :class="$style.required"
            >*</span></span>
        </div>
        <div
          v-show="showPassword"
          class="position-absolute end-0 text-primary me-3"
          role="button"
          @click="setShowPassword(false)"
        >
          <Icon
            name="eye-open"
            :class="$style[`icon`]"
          />
        </div>
        <div
          v-show="!showPassword"
          class="position-absolute end-0 text-primary me-3"
          role="button"
          @click="setShowPassword(true)"
        >
          <Icon
            name="eye-close"
            :class="$style[`icon`]"
          />
        </div>
      </div>
    </div>
    <div
      :class="{'col-8': direction==='row'}"
    >
      <small
        class="text-danger position-absolute start-0"
        :class="[$style[`error-text`],{[$style[`show`]]: errMessage!==''}]"
      >{{ errMessage }}</small>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import { watch } from '@vue/runtime-core'

export default {
  name: 'Input',
  props: {
    input: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: 'text'
    },
    errMessage: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 100
    },
    direction: {
      type: String,
      default: 'column'
    }
  },
  emits: ['focus', 'enter', 'update:input'],
  setup (props, { emit }) {
    const showPassword = ref(false)
    const setShowPassword = (show) => {
      showPassword.value = show
    }

    const inputValue = ref(props.input)
    const setInputValue = value => {
      inputValue.value = value
    }
    const updateInput = event => {
      emit('update:input', event.target.value)
    }

    watch(() => props.input, () => {
      if (props.input !== inputValue.value) {
        setInputValue(props.input)
      }
    })

    return {
      inputValue, updateInput, showPassword, setShowPassword
    }
  }
}
</script>

<style lang="scss" module>
.label {
  transition: opacity 0.3s;
}

.label-column {
  opacity: 0;
  transform: translateY(-60%);
}

.input:focus ~ .label {
  opacity: 1;
}

.required {
  color: $red-200;
}

.icon {
  height: 1.2rem;
  width: 1.2rem;
}

.error-text {
  display: inline-block;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.3s;
}

.error-text.show {
  opacity: 1;
}
</style>
